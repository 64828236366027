import React from 'react';
import CloseImg from '../images/icons/Close-button.svg';
import s from './Modal.module.scss';

class Modal extends React.Component {

  componentDidMount() {
    if (this.props.showModal) {
      document.body.classList.add(s.lock);
      document.querySelector('html').classList.add(s.lock)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      document.body.classList.add(s.lock);
      document.querySelector('html').classList.add(s.lock)
    } else if (this.props.showModal !== prevProps.showModal) {
      document.body.classList.remove(s.lock);
      document.querySelector('html').classList.remove(s.lock)
    }
  }

  componentWillUnmount() {
    document.body.classList.remove(s.lock);
    document.querySelector('html').classList.remove(s.lock)
  }

  render() {
    const {
      showModal,
      closeButton = true,
      children,
      closeOnBlur = false,
      backgroundColor = 'rgba(51, 51, 51, 0.7)',
      color = '#000',
      closeModal,
      width = '100%',
      style = {},
      className = '',
      id,
    } = this.props;

    return (
      <div className={s.modal} id={id}>
        <div
          style={{ display: showModal ? 'block' : 'none', backgroundColor }}
          className={s.background}
          onMouseDown={closeOnBlur ? closeModal : undefined}
          role="button" tabIndex="0" 
        >
          <div
            style={{ display: showModal ? 'block' : 'none', color, width, ...style }}
            className={`${s.modalContainer} ${className}`}
            onMouseDown={e => e.stopPropagation()}
            onClick={e => e.stopPropagation()}
            onKeyDown={e => e.stopPropagation()}
            role="button" tabIndex="0" 
          >
            {closeButton ?
              <span
                className={s.close}
                onClick={closeModal}
                onKeyDown={closeModal} 
                role="button" tabIndex="0" 
              >
                <img src={CloseImg} alt="Cerrar" />
              </span>
              : null
            }
            {children}
          </div>
        </div>
      </div>
    );
  }
}


export default Modal;
