import React from 'react';
import s from './FormCv.module.scss';
import clip from '../images/icons/clip.svg';
import Input from './Input';
import Button from './Button';

const FormCv = ({ title, first_name, last_name, email, phone, errors, handleCvInfo, handleFile, cvInfoSubmit, cv }) => {

    return (
        <div>
            {title && <h3 className={s.title}>{title}</h3>}
            <p className={s.text}>Completá el siguiente formulario y adjuntá tu C.V.</p>
            <div className={s.cvInfoContainer}>
                <Input name='first_name' value={first_name} label='Nombre' onChange={handleCvInfo} style={{width: '90%'}}/>
                <Input name='last_name' value={last_name} label='Apellido'  onChange={handleCvInfo} style={{width: '90%'}}/>
                <Input name='email' label='Email' value={email} className={s.inputLarge} onChange={handleCvInfo} error={errors.email && errors.email[0]} style={{width: '90%'}}/>
                <Input name='phone' label='Celular' type='tel' value={phone} className={s.inputLarge}  onChange={handleCvInfo} error={errors.phone && errors.phone[0]} style={{width: '90%'}}/>
                {errors.phone && errors.phone[0] ? null : <p className={s.legalText}>Ej.: 1160001234 o 2216000123 (Cod. de área sin el 0 + Número sin el 15)</p>}
                <label>
                    <div className={s.transparentButton}>
                        <img src={clip} className={s.uploadImg} alt='Ícono de subir C.V.'/>
                        <p>Adjuntá tu C.V.(.PDF o .doc)</p>
                    </div>
                    <input onChange={handleFile} style={{ alignSelf: 'right', display: 'none' }} type="file" value='' />
                </label>
                {cv && <p className={s.cvFile}>{cv.name}</p>}
                {errors.cv && <p className={s.error}>{errors.cv[0]}</p>}
                <Button text='Enviar' onClick={cvInfoSubmit} style={{marginTop: '40px'}} disabled={(first_name && last_name && phone && email && cv) ? false : true}/>
            </div>
        </div>
    )
};

export default FormCv;
