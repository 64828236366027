import React from 'react';
import { Link } from "gatsby";
import s from './GoBackButton.module.scss';
import leftarrow from '../images/icons/Green-arrow.svg';

const GoBackButton = ({ onClick, children, link, childrenGeneral, style }) => {

    return(
        <div className={s.container}>
            <Link to={(link) ? link : ''} onClick={onClick} className={s.buttonContainer} style={style}>
                <img className={s.imgButton} src={leftarrow} alt="flechas verde"/>
                {childrenGeneral && <p className={s.gbText} >{`${(childrenGeneral) ? childrenGeneral : ''}`}</p>}
                {children && <p className={s.gbChildren} >{`${(children) ? children : ''}`}</p>}
            </Link>
        </div>
    );
}
export default GoBackButton;
