import React from "react";
import "../style/style.module.scss";
import { useStaticQuery, graphql } from "gatsby";
import NavBar from '../components/NavBar';
import s from '../style/vacanctPage.module.scss';
import { TagManager, TagManagerFooter } from '../components/TagManager';
import Footer from '../components/Footer';
import SendCv from '../components/SendCv';
import MetaData from '../components/MetaData';
import PageView from '../components/PageView';
import VacancyBodyContainer from '../components/VacancyBodyContainer';



const VacanctPage = ({ location }) => {
    const data = useStaticQuery(graphql`{
        gcms {
            jobVacancies (where: {status: PUBLISHED}){
                title
                shortDescription
                longDescription {
                    html
                }
                tag1
                tag2
                tag3
                tag4
                tag5
                link
                jobType
                status
            }
            
            rubrosJobses (where: {status: PUBLISHED}) {
                title
                vacancy
                icon{
                    url
                }
                link
            }
        }
    }`).gcms;
    
    return(
      <div>
            <MetaData/>
            <TagManager/>
            <PageView/>
            <div className={s.headerContainer}>
                <NavBar blue={true}/>
            </div>
            <VacancyBodyContainer data={data} location={location}/>
            <SendCv/>
            <Footer jobs={true}/>
            <TagManagerFooter/>
      </div>
    )
}

export default VacanctPage;
