import React from 'react';
import s from './SelectVacancy.module.scss';

const SelectVacancy = ({ name, options, onChange, value=null }) => {
    return(
        <div className={`${s.container}`}>
            <select name={`${name}`} onBlur={onChange} onChange={onChange} value={value || 'Todos'} className={s.select}>
                {options && options.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
            </select>
        </div>
    );
}
export default SelectVacancy
