import React from "react";
import s from './VacancyBodyContainer.module.scss';
import JobVacancy from './JobVacancy';
import SelectVacancy from './SelectVacancy';
import GoBackButton from './GoBackButton';
import { navigate } from "gatsby";


class VacancyBodyContainer extends React.Component {
    state = {
        defaultVacancy: 'Todos',
        jobs: [],
        vacancyValue: '',
        vacancyOptions: [],
        selectedJobs: [],
    }

    componentDidMount(){
        const { data, location } = this.props;
        if(location.hash){
            this.setState({
                selectedJobs: data.jobVacancies.filter(item => item.jobType.toLowerCase() === location.hash.split('#')[1]),
                vacancyOptions: [...data.rubrosJobses.map((x) => x.title), 'Todos'],
                vacancyValue:  data.rubrosJobses.find(item => item.title.toLowerCase() === location.hash.split('#')[1]).title,
                jobs: data.jobVacancies,
            });
            
        }else{
            this.setState({
                selectedJobs: data.jobVacancies,
                vacancyOptions: [...data.rubrosJobses.map((x) => x.title), 'Todos'],
                vacancyValue:  'Todos',
                jobs: data.jobVacancies,
            });
        }
    }

    selectVacancyChange = (e) => {
        let value = e.target.value;
        const { jobs } = this.state;
        if(value !== 'Todos'){
            let selectedJobs = jobs.filter((item) => item.jobType === value);
            this.setState({
                selectedJobs: selectedJobs,
                vacancyValue: value,
            });
            let link = value && value.toLowerCase();
            navigate(
                `/vacantes#${link}`,
                { replace: true }
            )
        }else{
            this.setState({
                selectedJobs: jobs,
                vacancyValue: value,
            });
            navigate(
                `/vacantes`,
                { replace: true }
            )
        }
        
    }

    render(){
        const { defaultVacancy, vacancyOptions, vacancyValue, selectedJobs } = this.state;
        return(
            <div className={s.vacancyBody}>
                <GoBackButton children='Volver a Jobs' link='/jobs'/>
                <SelectVacancy name='vacancy'  value={vacancyValue}  options={vacancyOptions} onChange={this.selectVacancyChange} defaultVacancy={defaultVacancy}/>
                <h2 className={s.title}>Encontrá nuestras ofertas laborales</h2>
                {selectedJobs && selectedJobs.map((item, index) =>
                    <JobVacancy item={item} key={index}/>
                )}
            </div>
        )
    }
}

export default VacancyBodyContainer