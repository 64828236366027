import React from "react";
import axios from 'axios';
import { URL_BASE } from '../config.js';
import s from './JobVacancy.module.scss';
import arrow_up from '../images/icons/up-arrow-blue.svg';
import arrow_down from '../images/icons/down-arrow-blue.svg';
import Button from './Button.js';
import Modal from './Modal.js';
import FormCv from './FormCv';
import { isValidCharacter } from '../../utils';


class JobVacancy extends React.Component {
    state = {
        collapse: false,
        showCvModal: false,
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        cv: {},
        errors: {
            email: '',
            phone: '',
            cv: '', 
        },
    }

    componentDidMount = () => {
       document.addEventListener('click', this.handleClick, false)
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClick, false)
    }

    handleCollapse = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            collapse: !this.state.collapse
        })
    }

    handleClick = (e) =>{
        if(!this.node.contains(e.target)){
            this.setState({
                collapse:false
            })
        }
    }

    handleCvInfo = (e) =>{
        e.preventDefault();
        e.stopPropagation();
        let name = e.target.name;
        let value = e.target.value;
        if(isValidCharacter(name, value)){
            this.setState({
                [name]: value,
            });
        }
    }

    openSendCvModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            showCvModal: true,
        });
    }

    closeSendCvModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            showCvModal: false,
        });
    }

     handleFile = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        let file = e.target.files[0];
        this.setState({
            cv: file,
        });
    }

    cvInfoSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { first_name, last_name, phone, email, cv } = this.state;
        const { item } = this.props;

        
        let formPart = new FormData();
        formPart.append('first_name', first_name);
        formPart.append('last_name', last_name);
        formPart.append('email', email);
        formPart.append('phone', phone);
        formPart.append('area', item.title);
        formPart.append('cv', cv);

        axios.defaults.withCredentials = true
        axios.defaults.baseURL = URL_BASE;
        axios.post(`/api/v3/jobs/`,formPart,
        { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then((response)=> {
            this.setState({
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
                showCvModal: false,
                 cv: [],
                 errors: {
                    email: '',
                    phone: '',
                    cv: '', 
                },
            });
        })
        .catch(({ response }) => {
            const errors = {};
            Object.keys(response.data).forEach(field => {
                errors[field] = response.data[field];
            });
            this.setState({
                errors: errors,
            });
        });   
    }

    render(){
        const { index, item } = this.props;
        const { collapse, showCvModal, first_name, last_name, phone, email, errors, cv } = this.state;
        return(
            <div ref={node => this.node = node}>
            <div className={s.itemCard} key={index} onClick={this.handleCollapse} onKeyDown={this.handleCollapse} role="button" tabIndex="0" >
                <p className={s.title}>{item.title}</p>
                <div className={s.descriptionContainer}>
                    <p className={s.description}>{item.shortDescription}</p>
                    {(collapse) ? <img src={arrow_up} alt='Flecha arriba'/> : <img src={arrow_down} alt='Flecha abajo'/>}
                </div>
                <div className={(collapse) ? `${s.openElement} collapse` : s.collapseElement}>
                    <div dangerouslySetInnerHTML={{__html: item.longDescription.html}}></div>
                    <div className={s.buttonContainer}>
                        <Button text="APLICAR A ESTE PUESTO" onClick={this.openSendCvModal}/>
                        <Modal showModal={showCvModal} closeModal={this.closeSendCvModal}>
                            <FormCv title={item.title} first_name={first_name} last_name={last_name} email={email} phone={phone} errors={errors} cv={cv} handleCvInfo={this.handleCvInfo} handleFile={this.handleFile} closeSendCvModal={this.closeSendCvModal} cvInfoSubmit={this.cvInfoSubmit}/>
                        </Modal>
                    </div>
                </div>
                <div className={s.tagsContainer}>
                    {item.tag1 && <div className={s.tags}>{item.tag1}</div>}
                    {item.tag2 && <div className={s.tags}>{item.tag2}</div>}
                    {item.tag3 && <div className={s.tags}>{item.tag3}</div>}
                    {item.tag4 && <div className={s.tags}>{item.tag4}</div>}
                    {item.tag5 && <div className={s.tags}>{item.tag5}</div>}
                </div>
            </div>
        </div>    
        )
    }
}
export default JobVacancy
