import React from "react";
import axios from 'axios';
import { URL_BASE } from '../config.js';
import s from './SendCv.module.scss';
import uploadCv from '../images/icons/subi-tu-cv.svg';
import Button from './Button.js';
import Modal from './Modal.js';
import FormCv from './FormCv';
import { isValidCharacter } from '../../utils';

class SendCv extends React.Component {

    state = {
        showCvModal: false,
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        cv: {},
        errors: {
            email: '',
            phone: '',
            cv: '', 
        },
    }

    handleCvInfo = (e) =>{
        e.preventDefault();
        e.stopPropagation();
        let name = e.target.name;
        let value = e.target.value;
        if(isValidCharacter(name, value)){
            this.setState({
                [name]: value,
            });
        }
    }

    openSendCvModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            showCvModal: true,
        });
    }

    closeSendCvModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            showCvModal: false,
        });
    }

    handleFile = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        let file = e.target.files[0];
        this.setState({
            cv: file,
        });
    }

    cvInfoSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { first_name, last_name, phone, email, cv } = this.state;
        
        let formPart = new FormData();
        formPart.append('first_name', first_name);
        formPart.append('last_name', last_name);
        formPart.append('email', email);
        formPart.append('phone', phone);
        formPart.append('cv', cv);

        axios.defaults.withCredentials = true
        axios.defaults.baseURL = URL_BASE;
        axios.post(`/api/v3/jobs/`,formPart,
        { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then((response)=> {
            this.setState({
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
                showCvModal: false,
                cv: [],
                errors: {
                    email: '',
                    phone: '',
                    cv: '', 
                },
            });
        })
        .catch(({ response }) => {
            const errors = {};
            Object.keys(response.data).forEach(field => {
                errors[field] = response.data[field];
            });
            this.setState({
                errors: errors,
            });
        });   
    }


    render(){
        const { showCvModal, first_name, last_name, phone, email, errors, cv } = this.state;
        return (
        <div className={s.container} >
            <h1 className={s.title}>¿No encontraste lo que buscabas?</h1>
            <p className={s.subtitle}>Envianos tu C.V. a: <strong className={s.email}>jobs@agilis.com.ar</strong></p>
            <p className={s.subtitle}>ó</p>
            <Button text="Subí tu C.V." icon={uploadCv} onClick={this.openSendCvModal}/>
            <Modal showModal={showCvModal} closeModal={this.closeSendCvModal}>
                <FormCv first_name={first_name} last_name={last_name} email={email} phone={phone} errors={errors} cv={cv} handleCvInfo={this.handleCvInfo} handleFile={this.handleFile} closeSendCvModal={this.closeSendCvModal} cvInfoSubmit={this.cvInfoSubmit}/>
            </Modal>
        </div>
        )
    }
}

export default SendCv
